import styled from "styled-components";

export const ContainerCarousel = styled.div`
  width: 61%;

  flex-shrink: 0;
  transition: transform 0.5s ease-in-out;

  border-radius: 1.5rem;
  background-color: #FFF;

  > div > div > div > div > div > div {
    display: flex !important;
    justify-content: center;

    > img {
      width: 7.5rem;

      @media (max-width: 1024px) {
        width: 5.791rem;
      }

      @media (max-width: 768px) {
        width: 7.188rem;
      }

      @media (max-width: 479px) {
        width: 7.938rem;
      }

      @media (max-width: 420px) {
        width: 6.75rem;
      }

      @media (max-width: 375px) {
        width: 5.813rem;
      }

      @media (max-width: 320px) {
        width: 4.666rem;
      }
    }
  }

  @media (max-width: 1920px) {
    >div{
      button{
        display: none !important;
      }
    }
  }

  @media (max-width: 1550px) {
    width: 77%;
    
    >div{
      button{
        display: none !important;
      }
    }
  }

  @media (max-width: 1440px) {
    width: 82%;
    padding: 0.625rem;


  }

  @media (max-width: 1366px) {
    width: 87%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 1rem 2rem;
  }

  @media (max-width: 767px) {
    padding: 1.5rem 1rem;
  }
`;