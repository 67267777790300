import CommentUser1 from '../../../assets/carrossel-comentario-usuario-1.png'
import CommentUser2 from '../../../assets/carrossel-comentario-usuario-2.png'
import CommentUser3 from '../../../assets/carrossel-comentario-usuario-3.png'
import CommentUser4 from '../../../assets/carrossel-comentario-usuario-4.png'
import CommentUser5 from '../../../assets/carrossel-comentario-usuario-5.png'

export const CarouselCommentsData = [
   { 
      id: 1, 
      description: '"Agora temos uma empresa que nos dá suporte técnico para atualizações de bula, além da grande facilidade de se emitir um receituário agronômico. O processo tornou-se dinâmico e muito eficaz no dia a dia da cooperativa."',
      src: CommentUser1,
      name: "Nélio M. Uemura",
      Profession: "Engenheiro Agronômo",
      organization: "CACB"
   },
   { 
      id: 2,  
      description: '"A plataforma permite a emissão de receituários completos, de maneira descomplicada e rápida - até pelo celular! Além disso, conta com recursos modernos, como a assinatura digital, promovendo segurança e praticidade."',
      src: CommentUser2,
      name: "Lucas V. Simão",
      Profession: "Biólogo e Técnico Agrícola",
      organization: "VetBR"
   },
   { 
      id: 3,   
      description: '"Com o AgroReceita, não temos mais a preocupação em como trabalhar nessa parte de receituário, com burocracias e tudo mais, desde doses de produtos até equipamentos de proteção individual, a plataforma já deixa tudo pronto, e o importante, somente com cliques."',
      src: CommentUser3,
      name: "Luis P. Fazião",
      Profession: "Engenheiro Agronômo",
      organization: "Cooperativa Agrícola da Média Noroeste"
   },
   { 
      id: 4, 
      description: '"O software anterior não oferecia suporte, nem atendia às solicitações, o layout era limitado e permitia alterações em desacordo com a bula. Agora com a AgroReceita, temos uma plataforma completa, fácil de utilizar, dinâmica, suporte rápido e eficiente."',
      src: CommentUser4,
      name: "Lucas Mendes",
      Profession: "Engenheiro Agronômo",
      organization: "Operagro"
   },
   { 
      id: 5,  
      description: '“O suporte da AgroReceita é muito bom! Devo confessar que o Receituário Agronômico me assustava bastante, mas com o apoio da AgroReceita, me sinto mais segura em minhas recomendações e aspectos legais!”',
      src: CommentUser5,
      name: "Amanda Souza",
      Profession: "Engenheira Agronôma",
      organization: "Ceriales Perícias e Avaliações"
   },
];
