import React, { useState } from 'react';

import CadastroSite from '../cadastro/site';

import * as S from './styles'

export const compendioG = "@compendioG";

const RegistrationAndLogin = ({ ...props }) => {

    return (
        <S.Container id='teste-gratis'>
            <S.ContainerFormLogin>
                <S.ContainerInfo>
                    <S.ContainerImage>
                        <img src="https://agroreceita.com.br/wp-content/uploads/2020/07/logo-agroreceita-2.png" alt="" />
                    </S.ContainerImage>

                    <p>Aumente a produtividade das suas lavouras com AgroReceita</p>

                    <p>Decisões precisas e sustentáveis para proteger cada hectare.
                        <br />
                        Prescreva defensivos agrícolas com mais segurança e eficiência.</p>

                    <p>Maior Banco de Dados para a emissão do Receituário Agronômico.</p>
                </S.ContainerInfo>

                <S.ContainerForm>
                <iframe
                    src="https://sistema.agroreceita.com.br/cadastro/site"
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                    allowFullScreen
                />
                    {/* <CadastroSite /> */}
                </S.ContainerForm>
            </S.ContainerFormLogin>
        </S.Container>
    )
}

export default RegistrationAndLogin